import * as React from 'react'
import ComboWrapperGallery from "../components/ComboWrapperGallery"
import Seo from '../components/seo'
import Layout from '../components/layout'
import InfoGraphics from '../components/InfoGraphics'
import DullMoment from '../components/DullMoment'
import SportsBoulevard from '../components/SportsBoulevard'
import WhoWeAre from '../components/WhoWeAre'
import Guidelines from '../components/Guidelines'
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
export default function MediaGallery(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>
          <WhoWeAre
            title={data.wpPage?.pageSubtitle?.subtitle}
            name={data.wpPage?.title}
            description={data.wpPage.pageDescription?.pageDescription}
          />
          
          <ComboWrapperGallery data={data?.wpPage?.inTheNewsMG} locale={locale}/>
         
        
        </Layout>
        </>
    )
}

export const pageQuery = (graphql` query mediaGalleryData($databaseId:Int) {
   
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    content
    featuredImage {
      node {
        localFile {
          publicURL
        }
      }
    }
    pageDescription {
      pageDescription
    }
    inTheNewsMG {
    
      
     
      images {
        localFile {
          publicURL
        }
        date(formatString: "DD-MM-YYYY")
      }
      videos {
       
        youtubelink
        date
        
      }
      
    }
  }
}`);