import React, { useEffect, useContext, useState } from "react"
import ReactModal from 'react-modal'
import "./comboWrapperGallery.scss"
import { string_translation,chunk_array } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import SliderNavigation from '../../components/SharedComponents/SliderNavigation'

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import { createMarkup } from "../../../utils"

export default function ComboWrapperGallery(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      videos_ar:translateString(language: AR, string: "VIDEOS")
      videos_en:translateString(language: EN, string: "VIDEOS")
      gallery_ar:translateString(language: AR, string: "GALLERY")
      gallery_en:translateString(language: EN, string: "GALLERY")
      all_ar:translateString(language: AR, string: "ALL")
      all_en:translateString(language: EN, string: "ALL")
      imageLabel_ar:translateString(language: AR, string: "صورة")
      imageLabel_en:translateString(language: EN, string: "Image")
      videoLabel_ar:translateString(language: AR, string: "فيديو")
      videoLabel_en:translateString(language: EN, string: "Video")
    }
  }`) 
  const gallery = props.data.images
  const videos = props.data.videos
  console.log(props);
  let chunked_array = chunk_array(gallery, 3)
  const swiperRef = React.useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleModalOpen = event => {

    setOpen(true)
  }


  const handleModalClose = event => {
    setOpen(false)
  }

  let hash="#image-gallery";
  if (typeof window !== 'undefined') {
    try{
  if(sessionStorage.getItem('hashgallery')){
    hash=sessionStorage.getItem('hashgallery');
  }
}catch(ex){
        
}
  if (window.location.hash) {
    hash=window.location.hash;
  }
}

useEffect(
  () => {
    if (hash) {
       setActiveTab(hash)
    }
  }
)
function setActiveTab(hashurl){
  var elements = document.getElementsByClassName("gallerylistheader")[0].getElementsByTagName("li");
    for (var i = 0; i < elements.length; i++) {
    if(elements[i].dataset.tab==hashurl){
      elements[i].classList.add("active");
    }else{
      elements[i].classList.remove("active");
    }
  }


  var elementsdown = document.getElementsByClassName("listbottomgallery")[0].getElementsByTagName("div");

    for (var i = 0; i < elementsdown.length; i++) {
      if(elementsdown[i].id!=""){
    if('#'+elementsdown[i].id==hashurl){
      elementsdown[i].classList.add("active");
    }else{
      elementsdown[i].classList.remove("active");
    }
  }
  }
}
function updatehash(hash){
  if (typeof window !== 'undefined') {
    try{
    sessionStorage.setItem('hashgallery', hash);
  }catch(ex){
        
  }
  }
  
var arabic = document.querySelectorAll(".smail-menu-item,.arabic");
  try{
   for ( let i=0 ; i < arabic.length ; i++) {
    var elem = arabic[i];
     if(elem.childNodes){
     const url = elem.childNodes[0].href;
     const urlObj = new URL(url);
      urlObj.hash = hash;
      const result = urlObj.toString();
     elem.childNodes[0].href=result;
     }

    }
  }catch(aaa){

  }
}
 

  ReactModal.setAppElement('#___gatsby')
  let showModal = true
console.log(chunked_array);
  return (
    <>
      <section className="combo-wrapper">
        <div className="container">
          <div className="combo-box-tab">
            <ul className="tabs tabs-list h2 gallerylistheader" data-controls="#drf">
            
              <li className="active" data-tab="#image-gallery" onClick={(e) => updatehash('#image-gallery')}>
                {string_translation(stringTranslations, "gallery", locale)}
              </li>
              <li data-tab="#videos" onClick={(e) => updatehash('#videos')}>
                {string_translation(stringTranslations, "videos", locale)}
              </li>
              <li data-tab="#the-all" onClick={(e) => updatehash('#the-all')}>
                {string_translation(stringTranslations, "all", locale)}
              </li>
            </ul>
          </div>
          <div className="tab-container listbottomgallery" id="drf">
            <div className="resizing" id="videos">
            {videos.length > 0 ? (
          <div className="col-group last-updates-posts">
            {videos.map((item, index) => {
       return (
                <div className="col-dt-4 element" key={"n" + index}>
               
                
                    
                    <div className="spotlight-img imagesize">
                    <iframe   src={item.youtubelink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                    </div>
                    <div className="action-section">
                  <div className="download-wrapper">
                    <div className="download-img-wrapper">
                      <a href={item.youtubelink}  rel="noopener noreferrer">
                        <img src="/images/infographics/view-online.svg" alt="Download" />
                      </a>
                    </div>
                  </div>
               
                  </div>
                    <div className="date-wrapper">
                      <div className="date-tag">
                      {string_translation(stringTranslations, "videoLabel", locale)}
                      </div>
                      <hr className="date-hr" />
                      <div className="date">{item.date}</div>
                    </div>
                
                </div>
                 )
               })}
             </div>
                  ): (
          <div className="no-result-div">
            <div className="result-wrapper">
              <div className="highlight">{string_translation(stringTranslations, "highlight", locale)}</div>
              <div className="text">
              {string_translation(stringTranslations, "text1", locale)}
              </div>
              <div className="text">
              {string_translation(stringTranslations, "text2", locale)}
              </div>
            </div>
          </div>
        )}
          
            </div>
            <div className="active resizing" id="image-gallery">
            {gallery.length > 0 ? (
          <div className="col-group last-updates-posts">
            {gallery.map((item, index) => {
              return (
                <div className="col-dt-4 element" key={"n" + index}>
               
                
                    
                    <div className="spotlight-img imagesize">
                      <img
                        src={
                          item.localFile?.publicURL
                        }
                        alt={item.localFile?.publicURL.substring(0, 147).replace(/<[^>]*>?/gm, '')}
                      />
                    </div>
                    <div className="action-section">
                  <div className="download-wrapper">
                    <div className="download-img-wrapper">
                      <a href={item.localFile?.publicURL}  rel="noopener noreferrer">
                        <img src="/images/infographics/view-online.svg" alt="Download" />
                      </a>
                    </div>
                  </div>
               
                </div>
                    <div className="date-wrapper">
                      <div className="date-tag">
                      {string_translation(stringTranslations, "imageLabel", locale)}
                      </div>
                      <hr className="date-hr" />
                      <div className="date">{item.date}</div>
                    </div>
                
                </div>
              )
            })}
          </div>
        ): (
          <div className="no-result-div">
            <div className="result-wrapper">
              <div className="highlight">{string_translation(stringTranslations, "highlight", locale)}</div>
              <div className="text">
              {string_translation(stringTranslations, "text1", locale)}
              </div>
              <div className="text">
              {string_translation(stringTranslations, "text2", locale)}
              </div>
            </div>
          </div>
        )}
            </div>
            <div className="resizing" id="the-all">
            {gallery.length > 0 ? (
          <div className="col-group last-updates-posts">
            {gallery.map((item, index) => {
              return (
                <div className="col-dt-4 element" key={"n" + index}>
               
                
                    
                    <div className="spotlight-img imagesize">
                      <img
                        src={
                          item.localFile?.publicURL
                        }
                        alt={item.localFile?.publicURL.substring(0, 147).replace(/<[^>]*>?/gm, '')}
                      />
                    </div>
                    <div className="action-section">
                  <div className="download-wrapper">
                    <div className="download-img-wrapper">
                      <a href={item.localFile?.publicURL}  rel="noopener noreferrer">
                        <img src="/images/infographics/view-online.svg" alt="Download" />
                      </a>
                    </div>
                  </div>
               
                </div>
                    <div className="date-wrapper">
                      <div className="date-tag">
                      {string_translation(stringTranslations, "imageLabel", locale)}
                      </div>
                      <hr className="date-hr" />
                      <div className="date">{item.date}</div>
                    </div>
                
                </div>
              )
            })}
             {videos.map((item, index) => {
       return (
                <div className="col-dt-4 element" key={"n" + (index+(gallery.length))}>
               
                
                    
                    <div className="spotlight-img imagesize">
                    <iframe   src={item.youtubelink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                    </div>
                    <div className="action-section">
                  <div className="download-wrapper">
                    <div className="download-img-wrapper">
                      <a href={item.youtubelink}  rel="noopener noreferrer">
                        <img src="/images/infographics/view-online.svg" alt="Download" />
                      </a>
                    </div>
                  </div>
               
                  </div>
                    <div className="date-wrapper">
                      <div className="date-tag">
                      {string_translation(stringTranslations, "videoLabel", locale)}
                      </div>
                      <hr className="date-hr" />
                      <div className="date">{item.date}</div>
                    </div>
                
                </div>
                 )
               })}
          </div>
        ): (
          <div className="no-result-div">
            <div className="result-wrapper">
              <div className="highlight">{string_translation(stringTranslations, "highlight", locale)}</div>
              <div className="text">
              {string_translation(stringTranslations, "text1", locale)}
              </div>
              <div className="text">
              {string_translation(stringTranslations, "text2", locale)}
              </div>
            </div>
          </div>
        )}
            </div>
          </div>
          <div></div>
        </div>
      </section>
    </>
  )
}
